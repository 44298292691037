<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                :activateRecord="false"
                backPath="/integracao-apollus"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="integracao-apollus-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="card p-fluid w-full" v-if="!form.id">
                        <div class="grid">
                            <div class="field col-6">
                                <label for="idCliente">Cliente</label>
                                <DropdownCliente id="idCliente" :disabled="!!form.id" v-model="form.cliente" :idValue="form.idCliente" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="dataInicio">Inicio pesquisa</label>
                                <Calendar id="dataInicio" v-model="form.dataInicio" />
                            </div>
                            <div class="field col-6">
                                <label for="dataFim">Fim pesquisa</label>
                                <Calendar id="dataFim" v-model="form.dataFim" />
                            </div>
                        </div>
                    </div>
                    <div class="card p-fluid w-full" v-if="form.id">
                        <div class="grid">
                            <div class="field col-6">
                                <label for="id">Código</label>
                                <InputText :disabled="!!form.id" id="id" v-model.trim="form.id" required="true" autofocus autocomplete="off" />
                            </div>

                            <div class="field col-6">
                                <label for="idCliente">Cliente</label>
                                <DropdownCliente id="idCliente" :disabled="!!form.id" v-model="form.cliente" :idValue="form.idCliente" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6" v-if="form.id">
                                <label for="status">Status</label>
                                <InputText :disabled="true" id="status" v-model.trim="form.status" />
                            </div>

                            <div class="field col-6">
                                <label for="keyAlteracao">Key Alteração</label>
                                <InputText :disabled="true" id="keyAlteracao" v-model.trim="form.keyAlteracao" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6" v-if="form.id">
                                <label for="inicioEnvioApollus">Inicio envio Apollus</label>
                                <InputText :disabled="true" id="inicioEnvioApollus" v-model.trim="form.inicioEnvioApollus" />
                            </div>

                            <div class="field col-6">
                                <label for="fimEnvioApollus">Fim envio Apollus</label>
                                <InputText :disabled="true" id="fimEnvioApollus" v-model.trim="form.fimEnvioApollus" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6" v-if="form.id">
                                <label for="codigoFichaSoc">Código ficha SOC</label>
                                <InputText :disabled="!editable()" id="codigoFichaSoc" v-model.trim="form.codigoFichaSoc" />
                            </div>

                            <div class="field col-6">
                                <label for="codigoAsoApollus">Código ASO Apollus</label>
                                <InputText :disabled="!editable()" id="codigoAsoApollus" v-model.trim="form.codigoAsoApollus" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12" v-if="form.id">
                                <label for="dadosSoc">SOC JSON</label>
                                <Textarea id="dadosSoc" v-model="form.dadosSoc" :disabled="!editable()" rows="7" cols="30" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12" v-if="form.id">
                                <label for="dadosApollus">Apollus JSON</label>
                                <Textarea id="dadosApollus" v-model="form.dadosApollus" :disabled="!editable()" rows="7" cols="30" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12" v-if="form.id">
                                <label for="erro">Erro</label>
                                <Textarea id="erro" v-model="form.erro" :disabled="true" rows="7" cols="30" />
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import BaseService from '../../services/BaseService';
import DropdownCliente from '../clientes/components/DropdownCliente.vue';

export default {
    components: { DropdownCliente, AppInfoManual },
    data() {
        return {
            loading: false,
            service: null,
            form: {},
            submitted: false
        };
    },
    created() {
        this.service = new BaseService('/integracao-apollus');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Visualizar integração' : 'Adicionar importação';
        }
    },
    methods: {
        editable() {
            return this.form.status == 'ERRO';
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            return false;
        },
        async onBeforeSave() {
            this.submitted = true;
            this.form.idCliente = this.form.cliente?.id;
        },
        async onAfterSave() {
            this.submitted = false;
        }
    }
};
</script>
